import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["flex-col flex-justify-center flex-align-center ds-btn", { selected: _ctx.shortcut.selected }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onSelect', _ctx.shortcut.date)))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.dayStr), 1),
    _createElementVNode("span", null, _toDisplayString(_ctx.dateStr), 1)
  ], 2))
}
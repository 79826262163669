import { Time } from './../../common/models/Time.model';
import { Availability } from './../../common/models/Availability.model';
import { Product } from './../../common/models/Product.model';
import { apiService } from '@/services/api.service';
import { Item } from './../../common/models/Item.model';
import { Action, Module, VuexModule } from 'vuex-class-modules';
import store from '@/store';
import { format } from 'date-fns';
import { DayOfTheMonth } from '@/common/models/DayOfTheMonth.model';
import { clientModule } from '../client/client.vuex-module';

@Module({ generateMutationSetters: true })
class CheckoutModule extends VuexModule {
	private _catalogView: 'list' | 'grid' = 'grid';
	private _openItem: Item | null = null;
	private _monthAvailability: Map<string, DayOfTheMonth[]> = new Map();
	private _availableTimes: Time[] = [];
	private _openProduct: Product | null = null;
	private _searchQuery = '';

	isLoading = false;

	get catalogView() {
		return this._catalogView;
	}

	get openItem() {
		return this._openItem;
	}

	get monthAvailability() {
		return this._monthAvailability;
	}

	get availableTimes() {
		return this._availableTimes;
	}

	get rates() {
		return this.activity?.Rates ?? [];
	}

	get guestCount() {
		return this.rates.reduce((p, c) => (p += c.Participants), 0);
	}

	get activity() {
		return this.openItem ? this.openItem.Activities[0] : null;
	}

	get searchQuery() {
		return this._searchQuery;
	}

	constructor() {
		super({ store, name: 'cartModule' });
	}

	@Action updateCatalogView(view: 'list' | 'grid') {
		this._catalogView = view;
	}

	@Action setOpenItem(e: Item) {
		this._openItem = e;
	}

	@Action async getMonthAvailability(d: Date): Promise<boolean> {
		if (this.activity) {
			this.isLoading = true;
			return await apiService
				.post('register/GetMonthAvailability', {
					IsPrivateTour: false,
					Date: format(d, 'yyyy-MM-dd'),
					EntityHierarchyKey: this.activity.EntityHierarchyKey,
					SpecificCulture: 'en-US',
					Rates: this.rates,
					ClientLocationKey: clientModule.clientLocationKey,
				})
				.then((res) => {
					if (Array.isArray(res)) {
						this._monthAvailability.set(format(d, 'MMMM-dd-yyyy'), res);
						return true;
					}
					return false;
				})
				.catch((e) => {
					console.error('Could not get month availability', e);
					return false;
				})
				.finally(() => (this.isLoading = false));
		} else return false;
	}

	@Action resetOpenItem() {
		this._openItem = null;
		this._availableTimes = [];
		this._monthAvailability.clear();
	}

	@Action async getTimes(d: Date) {
		if (this.activity) {
			this.isLoading = true;
			return await apiService
				.post('register/GetTimes', {
					AddOns: [],
					Activity: this.activity,
					PhotoPackageAddOns: [],
					IsPrivateTour: false,
					Date: format(d, 'yyyy-MM-dd') + 'T00:00:00',
					ClientLocationKey: this.activity.ClientLocationKey,
					Partner_ClientLocationKey: clientModule.clientLocationKey,
					Rates: this.rates,
					IsLargeGroup: false,
					EntityHierarchyKey: this.activity.EntityHierarchyKey,
					PreviousPrivateTourState: false,
				})
				.then((res: Availability[]) => {
					if (res.length) this._availableTimes = res[0].Times;
					return res;
				})
				.catch((e) => {
					console.error('Could not get activity times.', e);
					return null;
				})
				.finally(() => (this.isLoading = false));
		}
		return null;
	}

	@Action updateSearchQuery(s: string) {
		this._searchQuery = s;
		console.log(this.searchQuery);
	}
}

export const checkoutModule = new CheckoutModule();


import { format, isToday } from 'date-fns';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'DateShortcutButton',
	emits: ['onSelect'],
})
export default class DateShortcutButton extends Vue {
	@Prop() shortcut!: { date: Date; selected: boolean };

	get dayStr() {
		if (isToday(new Date(this.shortcut.date))) return 'Today';

		return format(new Date(this.shortcut.date), 'EEE');
	}

	get dateStr() {
		return format(this.shortcut.date, 'M/d');
	}
}

import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "order-window_header"
}
const _hoisted_3 = { class: "order-window_body" }
const _hoisted_4 = {
  key: 1,
  class: "order-window_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["order-window", { 'add-footer': _ctx.$slots.footer, 'add-header': _ctx.$slots.header }]),
    id: _ctx.windowId ?? ''
  }, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "footer")
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
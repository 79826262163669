import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "exp-section flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "section-header flex-row flex-justify-sb"
}
const _hoisted_3 = {
  key: 1,
  class: "section-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.$slots.summary)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass(["fw-heavy", { 'inactive-section': _ctx.inactive }])
          }, _toDisplayString(_ctx.label), 3),
          _renderSlot(_ctx.$slots, "summary")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_shortcuts = _resolveComponent("date-shortcuts")!
  const _component_month_availability = _resolveComponent("month-availability")!
  const _component_exp_section_base = _resolveComponent("exp-section-base")!

  return (_openBlock(), _createBlock(_component_exp_section_base, {
    label: 'Date and Time',
    inactive: !_ctx.activeLabel,
    id: "dateSection"
  }, _createSlots({
    summary: _withCtx(() => [
      (_ctx.showShortcuts)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_date_shortcuts, {
              selectedDate: _ctx.selectedDate,
              onSelectDate: _ctx.selectDate,
              onToggleCalendar: _ctx.toggleCalendar
            }, null, 8, ["selectedDate", "onSelectDate", "onToggleCalendar"])
          ]))
        : (_ctx.summary)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "highlight-text clickable summary-text",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onFocus')))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.summary), 1)
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.showCalendar && _ctx.isActiveSection)
      ? {
          name: "default",
          fn: _withCtx(() => [
            _createVNode(_component_month_availability, {
              selectedDate: _ctx.selectedDate,
              onOnSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectDate($event)))
            }, null, 8, ["selectedDate"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["inactive"]))
}

import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Options, Vue } from 'vue-class-component';
import TimeSlot from './time-slot.component.vue';
import ToggleButton from '@/common/components/form-ui/toggle-switch.component.vue';
import { InjectReactive, Prop, Watch } from 'vue-property-decorator';
import { add, startOfDay, isBefore } from 'date-fns';
import { clientModule } from '@/modules/client/client.vuex-module';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import { Time } from '@/common/models/Time.model';
import NumberTicker from '@/modules/order/shop/components/common/number-ticker.component.vue';
import { EntityHierarchyRate } from '@/common/models/EntityHierarchyRate.model';
import { OnClickOutside } from '@vueuse/components';
import { eventBus } from '@/proxies/event-bus.proxy';
import { BusEvent } from '@/common/enums/BusEvents.enum';
import SmallLoader from '@/common/components/small-loader.component.vue';
import OrderMenu from '@/modules/order/common/components/order-menu.component.vue';

@Options({
	name: 'TimeSlotSelector',
	components: { TimeSlot, ToggleButton, NumberTicker, OnClickOutside, SmallLoader, OrderMenu },
	emits: ['onTimeSelect', 'enableEditRates'],
})
export default class TimeSlotSelector extends Vue {
	@Prop({ default: false }) editRates!: boolean;
	@Prop({ default: null }) selectedDate!: Date | null;
	@InjectReactive() activity!: OrderActivity;

	viewOption: 'matched' | 'all' = 'matched';
	overrideOption = true; // swap to 'show all' if no best match on initial render

	showEditRatesMenu = false;
	showLargeGroupMenu = false;
	openTime: Time | null = null;
	timeRatesClone!: EntityHierarchyRate[];

	currentTime = new Date(clientModule.locationTime); // clone location time to prevent unnecessary update

	get isLargeGroup() {
		return this.activity.isLargeGroup;
	}

	get timeSlots() {
		if (!this.selectedDate || !this.activity.availableTimes.length) return [];

		if (this.viewOption == 'all') return this.activity.availableTimes;

		// "Best Match" filtering logic
		const dayStart = startOfDay(this.selectedDate as Date);
		const bestMatched = this.activity.availableTimes.filter((t) => {
			const tsTime = t.DisplayName.split(' ')[0].split(':'); // parse time name into parts
			let hour = Number(tsTime[0]);
			hour += t.DisplayName.includes('PM') && hour < 12 ? 12 : 0;
			const minute = Number(tsTime[1]);
			const ts = add(dayStart, { hours: hour, minutes: minute });
			return isBefore(this.currentTime, ts) && (orderModule.isLargeGroup ? true : t.TotalAvailable >= this.activity.rateCount);
		});

		if (bestMatched.length) return bestMatched;
		else {
			if (this.overrideOption)
				setTimeout(() => {
					this.overrideOption = false;
					this.toggleOption('all'); // allow for selector to be rendered
				});
			return [];
		}
	}

	get selectedTimes() {
		return this.activity.selectedTimeKeys;
	}

	get loadingTimes() {
		return !!(!this.activity.selectedTimes.length && this.activity.isLoading);
	}

	mounted() {
		// set initial background width
		document.getElementById('optBg')!.style.width = document.getElementById(`${this.viewOption}Option`)?.getBoundingClientRect().width + 'px' ?? '60px';
	}

	toggleOption(opt: 'matched' | 'all') {
		if (opt != this.viewOption) {
			this.viewOption = opt;

			const bg = document.getElementById('optBg')!;
			bg.style.width = `${document.getElementById(`${this.viewOption}Option`)?.getBoundingClientRect().width}px`;
			bg.style.left = `${document.getElementById(`${this.viewOption}Option`)!.getBoundingClientRect().left - document.getElementById('viewOptions')!.getBoundingClientRect().left - 1}px`;

			// this.timesScroller();
		}
	}

	// scrolls component into view
	@Watch('timeSlots.length')
	timesScroller() {
		// if (this.activity.availableTimes.length) {
		if (this.timeSlots.length) {
			setTimeout(() => {
				if (clientModule.desktopView) (this.$parent?.$el as HTMLElement).scrollIntoView({ behavior: 'smooth' });
				else {
					// mobile positioning accounting for 60px header
					const pos = this.$parent?.$el.getBoundingClientRect().y - 60;
					if (pos)
						document.getElementById('addExpView')?.scrollTo({
							top: pos,
							behavior: 'smooth',
						});
				}
			});
		}
	}

	openTimeSlot(t: Time) {
		if (this.isLargeGroup || this.editRates) {
			this.openTime = t;
			this.timeRatesClone = JSON.parse(JSON.stringify(this.openTime.Rates));
			if (this.isLargeGroup) this.showLargeGroupMenu = true;
			else this.showEditRatesMenu = true;
		} else this.selectTimeslot(t);
	}

	editRatePrice(i: number, p: string) {
		if (this.openTime) {
			const price = parseInt(p.replace('$', ''));
			if (!isNaN(price)) this.openTime.Rates[i].Price = price;
		}
	}

	doneEditingRatePrices() {
		if (this.openTime) {
			this.openTime.SubTotal = this.openTime.Rates.reduce((p, c) => (p += c.Participants > 0 ? c.Participants * c.Price : 0), 0);
			this.selectTimeslot(this.openTime);
		}
	}

	assignGroupParticipants(i: number, v: number) {
		if (this.openTime) {
			this.openTime.Rates[i].Participants = v;
		}
	}

	doneAssigningGroupParticipants() {
		if (this.openTime) {
			this.openTime.Participants = this.openTime.Rates.reduce((p, c) => (p += c.Participants), 0);
			this.selectTimeslot(this.openTime);
		}
	}

	selectTimeslot(t: Time) {
		this.activity.selectTime(t);
		this.closeMenu();
		this.$emit('onTimeSelect');
	}

	onCancel() {
		if (this.openTime) this.openTime.Rates = this.timeRatesClone;
		this.closeMenu();
	}

	closeMenu() {
		eventBus.emit(BusEvent.TOGGLE_ORDER_MENU, false);

		setTimeout(
			() => {
				this.openTime = null;
				this.showEditRatesMenu = false;
				this.showLargeGroupMenu = false;
			},
			clientModule.desktopView ? 0 : 250, // allow time for mobile menu slide animation
		);
	}

	toggleEditRates() {
		this.$emit('enableEditRates', !this.editRates);
	}
}


import { ClientMixin } from '@/common/mixins/client.mixin';
import { Icons, iconLibrary } from '@/common/utilities/iconsLibrary.utility';
import { mixins, Options, Vue } from 'vue-class-component';

@Options({
	name: 'Close Button',
	components: {},
	emits: ['close'],
})
export default class CloseButton extends mixins(ClientMixin) {
	Icons = Icons;
	iLib = iconLibrary;
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "order-menu-wrapper" }
const _hoisted_2 = { class: "flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_button = _resolveComponent("close-button")!
  const _component_order_window = _resolveComponent("order-window")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#orderMenu" }, [
    _createVNode(_component_OnClickOutside, {
      onTrigger: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_order_window, { windowId: _ctx.menuId }, _createSlots({
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 2
          }, [
            (_ctx.$slots.menuHeader)
              ? {
                  name: "header",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.mobileView)
                        ? (_openBlock(), _createBlock(_component_close_button, {
                            key: 0,
                            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClose')))
                          }))
                        : _createCommentVNode("", true),
                      _renderSlot(_ctx.$slots, "menuHeader")
                    ])
                  ]),
                  key: "0"
                }
              : undefined,
            (_ctx.$slots.menuFooter)
              ? {
                  name: "footer",
                  fn: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "menuFooter")
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1032, ["windowId"])
        ])
      ]),
      _: 3
    })
  ]))
}
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
  }, [
    _createElementVNode("i", {
      innerHTML: _ctx.desktopView ? _ctx.iLib.getIcon(_ctx.Icons.CLOSE_BUTTON_1, 24, 24, 1.5) : _ctx.iLib.getIcon(_ctx.Icons.CHEVRON_LEFT, 30, 30, 2)
    }, null, 8, _hoisted_1)
  ]))
}
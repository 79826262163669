
import { Options, Vue } from 'vue-class-component';
import ExpSectionBase from './exp-section-base.component.vue';
import TimeSlotSelector from './date/time-slots-selector.component.vue';
import { InjectReactive } from 'vue-property-decorator';
import MonthAvailability from './date/month-availability.component.vue';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import DateShortcuts from './date/date-shortcuts.component.vue';
import { CapacityType } from '@/common/enums/CapacityType.enum';
import OrderMenu from '@/modules/order/common/components/order-menu.component.vue';
import { clientModule } from '@/modules/client/client.vuex-module';

@Options({
	name: 'TimeSection',
	components: { ExpSectionBase, DateShortcuts, TimeSlotSelector, MonthAvailability, OrderMenu },
	emits: ['onFocus', 'onNext'],
})
export default class TimeSection extends Vue {
	@InjectReactive() activeSection!: string;
	@InjectReactive() activity!: OrderActivity;

	editRates = false; // keep option persistent through order flow
	warningMsgs: string[] = [];

	get activeLabel() {
		return this.isActiveSection || this.hasRates;
	}

	get desktopView() {
		return clientModule.desktopView;
	}

	get hasRates() {
		return !!this.activity.rateCount;
	}

	get isActiveSection() {
		return this.activeSection == 'times';
	}

	get selectedDate() {
		return this.activity.selectedDate;
	}

	get selectedTimes() {
		return this.activity.selectedTimes;
	}

	get showSummary() {
		return !this.isActiveSection && this.activity.availableTimes.length && this.hasRates;
	}

	get summary() {
		return this.activity.selectedTimes.length ? this.activity.selectedTimes[0].DisplayName : 'None';
	}

	handleTimeSelect() {
		if (this.activity.capacityType == CapacityType.DURATION) {
			/** check for any overlaps for duration based activities **/
			const selTime = this.selectedTimes[0]; // only one time for duration allowed
			if (!selTime) return;

			const bookedRates = this.activity.rates.filter((rate) => rate.Participants > 0);
			const longestDur = Math.max(...bookedRates.map((rate) => rate.Duration!)); // longest duration rate

			const bookedPastClosed = selTime.Value + longestDur > selTime.ClosingTime!;

			const overbookedTimes: string[] = [];
			const futureTimes = this.activity.availableTimes.filter((time) => selTime.Value <= time.Value && time.Value <= selTime.Value + longestDur); // find any times duration goes over
			if (futureTimes.length) {
				for (const rate of bookedRates) {
					const rateDur = selTime.Value + rate.Duration!;
					for (const time of futureTimes) {
						if (rateDur <= time.Value) break;
						if (time.TotalAvailable < rate.Participants) {
							overbookedTimes.push(time.DisplayName);
						}
					}
				}
			}

			if (bookedPastClosed || overbookedTimes.length) {
				if (overbookedTimes.length) this.warningMsgs.push(`&bull; Overbook the ${overbookedTimes.join(', ')} time slot${overbookedTimes.length > 1 ? 's' : ''}.`);
				if (bookedPastClosed) {
					const hours = Math.floor(selTime.ClosingTime!);
					const minutes = selTime.ClosingTime! % 60;
					const am = hours < 12;
					this.warningMsgs.push(`&bull; Duration extends past closing at ${hours % 12}:${minutes} ${am ? 'AM' : 'PM'}.`);
				}
			} else this.$emit('onNext');
		}
	}

	onWarningCancel() {
		this.warningMsgs = [];
		this.activity.deselectTime(this.selectedTimes[0]);
	}

	onContinue() {
		this.warningMsgs = [];
		this.$emit('onNext');
	}
}

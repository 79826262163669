
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { InjectReactive, ProvideReactive } from 'vue-property-decorator';
import { Time } from '@/common/models/Time.model';
import moment from 'moment';
import DateSection from '@/modules/order/shop/components/add/experience/sections/date-section.component.vue';
import TimeSection from '@/modules/order/shop/components/add/experience/sections/time-section.component.vue';
import { clientModule } from '@/modules/client/client.vuex-module';

@Options({
	name: 'TimeEditView',
	components: { DateSection, TimeSection },
})
export default class TimeEditView extends Vue {
	@InjectReactive() time?: Time;
	@ProvideReactive() activeSection: 'dates' | 'rates' | 'times' | 'addons' | 'discounts' | 'none' = 'none';
	loading = true;
	get activityDate(): moment.Moment | null {
		if (!this.time) {
			return null;
		}
		return moment(this.time.Appointment.Time);
	}
	get activityDateString(): string {
		switch (this.time?.AppointmentTypeKey) {
			//Date Time
			case 1:
				return this.activityDate?.format('dddd, MMMM Do, YYYY [at] h:mma') ?? 'None selected';
			//Date Only
			case 2:
				return this.activityDate?.format('dddd, MMMM Do, YYYY') ?? 'None selected';
			//Any
			case 3:
				return '';
		}
		return '';
	}
	get selectedDate(): moment.Moment {
		return moment(this.activity?.selectedDate);
	}
	get getTimesLoading(): boolean {
		return orderModule.openActivity?.getTimesLoading ?? false;
	}
	get phoneNumber() {
		return clientModule.location?.PublicPhoneNumber;
	}
	selectTime() {
		if (this.activity?.availableTimes && this.activity.availableTimes[0]) {
			this.book(this.activity.availableTimes[0]);
		}
	}
	get canSubmit() {
		if (this.activity?.availableTimes && this.activity.availableTimes[0]) {
			return !this.selected(this.activity.availableTimes[0]);
		}
		return false;
	}
	selected(time: Time): boolean {
		if (!this.activityDate || !this.time) return false;
		return this.activityDate.isSame(this.selectedDate, 'day') && time.DisplayName == this.time.DisplayName;
	}
	setActiveSection(s: 'dates' | 'rates' | 'times' | 'addons' | 'discounts' | 'none'): void {
		if (this.activeSection == 'dates') {
			this.activity?.getBookTimes();
			console.log('Calling GBT from setActiveSection');
		}
		this.activeSection = s;
	}
	book(time: Time): void {
		if (this.selected(time)) {
			return;
		}
		if (this.activity) this.activity.pendingTime = time;
		this.$router.push('confirm');
	}
	get dates(): moment.Moment[] {
		let spread = [-1, 0, 1, 2];
		return spread.map((i) => moment(this.selectedDate).add(i, 'day'));
	}
	@ProvideReactive() get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get rates(): string | null {
		return (
			this.time?.Rates.filter((r) => r.Participants > 0)
				.map((r) => r.Name + ' x ' + r.Participants)
				.join(', ') ?? null
		);
	}
	get addons(): string | null {
		return (
			this.time?.AddOns.filter((a) => !a.IsDeleted)
				.map((r) => r.AddOnName + ' x ' + r.Quantity)
				.join(', ') ?? null
		);
	}
	mounted(): void {
		orderModule.isEditing = true;
		this.setActiveSection('dates');
		console.log('Calling GBT from mounted');
		if (orderModule.openActivity && this.time) orderModule.openActivity.activity.Rates = this.time.Rates;
		this.activity?.getBookTimes().then((res) => {
			this.loading = false;
		});
	}
	priceDifference(time: Time): number {
		if (this.time) {
			return Math.abs(this.time.Cost - time.SubTotal);
		}
		return 0;
	}
	priceClass(time: Time): { increase?: boolean; decrease?: boolean } {
		if (this.time) {
			if (this.time.Cost > time.SubTotal) {
				return { decrease: true };
			}
			if (this.time.Cost < time.SubTotal) {
				return { increase: true };
			}
		}
		return {};
	}
}


import { add, isBefore, isSameDay, startOfDay, sub } from 'date-fns';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import DateShortcutButton from './date-shortcut-btn.component.vue';

@Options({
	name: 'DateShortcuts',
	components: { DateShortcutButton },
	emits: ['toggleCalendar', 'selectDate'],
})
export default class DateShortcuts extends Vue {
	@Prop() selectedDate!: Date;

	start: Date = new Date(); // start on today

	refreshShortcuts(dir: 'back' | 'forward') {
		if (dir == 'back') {
			const day = sub(this.start, { days: 4 });
			if (isBefore(day, startOfDay(new Date()))) {
				this.start = new Date();
				return;
			}
			this.start = day;
		} else this.start = add(this.start, { days: 4 });
	}

	get dateShortcuts() {
		const days = [];
		for (let i = 0; i <= 3; i++) {
			const date = add(this.start, { days: i });
			days.push({
				date,
				selected: this.selectedDate && isSameDay(date, this.selectedDate),
			});
		}
		return days;
	}
	mounted(): void {
		if (this.selectedDate) {
			this.start = this.selectedDate;
		}
	}
}
